import { createAction } from "redux-actions";


export const DELETE_PLAN = "DELETE_PLAN";
export const deletePlan = createAction(DELETE_PLAN);

export const GET_PLANS = "GET_PLANS";
export const getPlans = createAction(GET_PLANS);

export const UPDATE_PLAN = "UPDATE_PLAN";
export const updatePlan = createAction(UPDATE_PLAN);

export const CREATE_PLAN = "CREATE_PLAN";
export const createPlan = createAction(CREATE_PLAN);
