import { all } from 'redux-saga/effects';
import app from './app';
import user from './user';
import company from './company';
import dashboard from './dashboard';
import plans from './plans';
const sagas = function* sagas() {
  yield all([app(), user(), company(), dashboard(), plans()]);
};

export default sagas;
